<template>
  <div>
    <v-col
        cols="12"
        md="12"
        lg="12"
        class="mx-auto pt-0"
      >
    <v-card class="">

      <v-card-title tag="h1" class="justify-center text-h5">
        {{$t('Indicadores de calidad de Perkins')}} 2021
      </v-card-title>

      <v-divider></v-divider>
      <v-card-text tag="p" class="text-start text-subtitle-1">
        {{$t('Estos indicadores son el resultado de una extensa discusión entre el personal profesional de Perkins Internacional y respetados colegas de diferentes regiones donde este programa presta servicios.')}}
        {{$t('Esta versión de 2021 para Latinoamérica surge de la iniciativa por llevar adelante un proceso de validación y actualización en función de los cambios de paradigma, la experiencia ganada por el equipo de especialistas y de la diversidad de entornos y participantes que están involucrados en las diferentes experiencias educativas con estudiante con sordoceguera, discapacidad sensorial y/o discapacidad múltiple.')}}
        {{$t('La perspectiva de revisión del instrumento es la que entiende a la evaluación como un proceso inherente a la educación que aporta una mirada crítica y reflexiva a la experiencia educativa. El resultado de la evaluación no marca un lugar de certezas absolutas, sino que permite analizar la experiencia educativa en su complejidad, entendida como una intersección de acciones, de implementación de estrategias y proyecciones')}}
        {{$t('Se espera que esta herramienta sea utilizada por los programas para la autorreflexión y autoevaluación, como parte del proceso de desarrollo de sus planes internos de formación y desarrollo del programa.')}}
        {{$t('Asimismo, también puede ser utilizada como una herramienta para los evaluadores externos, para medir la capacidad de un programa, desarrollar un plan para apoyar el futuro y calcular el impacto de este apoyo en la construcción de capacidad.')}}
      </v-card-text>
    </v-card>
    <v-card>
      <div class="mt-6">
        <v-expansion-panels>
          <v-expansion-panel>
            <v-expansion-panel-header>
              {{$t('Principios')}}
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <v-divider class="mb-5"></v-divider>
              <div>{{$t('LOS PRINCIPIOS ORIENTADORES DE ESTA REVISIÓN SON')}}</div>
              <br>
              <strong>{{$t('Prácticas Promotoras de Autonomía')}}</strong><br>
              <span>{{$t('Experiencias educativas que, por su valor innovador y transformador en la implementación de estrategias, disponibilidad de material, accesibilidad universal responden a las necesidades y demandas de los estudiantes para el desarrollo de su autonomía y participación.')}}</span>
              <br><br>
              <strong>{{$t('Trabajo Colaborativo')}} </strong><br>
              <span>{{$t('Dinámicas de trabajo que convocan a la participación de maestros, autoridades y familia, cuyas metas y estrategias son diseñadas e implementadas conjuntamente para lograr un trabajo articulado.')}}</span>
              <br><br>
              <strong>{{$t('Comunicación Transversal (principio y método)')}}</strong><br>
              <span>{{$t('Se considera a la comunicación como una filosofía de trabajo y como una estrategia metodológica.')}}
              {{$t('Como filosofía de trabajo: significa considerarla fundamental para el desarrollo del aprendizaje y las relaciones interpersonales, por lo cual se debe estudiar y planificar en todas las áreas de participación de los estudiantes.')}}
              {{$t('Como estrategia de trabajo: considerarla a través de decisiones metodológicas en la implementación de dinámicas, diseño de materiales y gestión de apoyos, dando respuesta a las diversidades funcionales de los estudiantes.')}}</span>
              <br><br>
              <strong>{{$t('Inclusión Educativa')}}</strong><br>
              <span>{{$t('Se trata de una cultura educativa basada en garantizar la igualdad de oportunidades y circunstancias para el aprendizaje y la continuidad de los estudiantes en el entorno escolar. Se toman decisiones en diferentes niveles: práctica educativa, política educativa y cultura educativa, procurando evitar los riesgos de exclusión.')}}
              </span>
              <br>
            </v-expansion-panel-content>
          </v-expansion-panel>
          <v-expansion-panel>
            <v-expansion-panel-header>
              {{$t('Objetivos de la implementación de los indicadores de calidad')}}
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <v-divider class="mb-5"></v-divider>
              <div><strong>{{$t('Identificar')}}</strong> {{$t('aquellas prácticas educativas que dan como resultado el logro de los objetivos buscados, definiendo el nivel de impacto en relación a las posibilidades que dan para la participación del estudiante con DM/DS, con el fin de poder replicarlos en otras instancias similares.')}}</div><br>
              <div><strong>{{$t('Retroalimentar')}}</strong> {{$t('al usuario del instrumento a través de la información que arroja, permitiéndole la toma de decisiones y el seguimiento según los principios sobre el que se sostiene el mismo.')}} </div>
            </v-expansion-panel-content>
          </v-expansion-panel>

          <v-expansion-panel>
            <v-expansion-panel-header>
              {{$t('Indicadores de calidad de Perkins - Características generales')}}
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <v-divider class="mb-5"></v-divider>
              <div>{{$t('Los indicadores están organizados en siete categorías de acuerdo a los tópicos que se evalúan.')}}</div><br>
              <strong>{{$t('Categorías de indicadores')}}</strong><br>
              <div>{{$t('Categoría 1 al 4: estas categorías agrupan indicadores que se focalizan en la escena más general de la escuela. A través de ellos se puede visibilizar participación, decisiones y organización que, a través de los acuerdos articulados, nos acercan a entender aspectos generales de la cultura escolar')}}<br>
              {{$t('Categorías del 5 al 7: estas categorías si bien contribuyen a un análisis general orientan la observación de individualidades situadas en la relación maestro-estudiante-familia de personas con DM/DS.')}}</div>
              <br>
              <div>{{$t('1- Conocimiento sobre el estudiante: conocimiento, toma de decisiones e instrumentación de herramientas para recolectar información sobre el estudiante. Esto permite valorarlo y a partir de ello diseñar planes de trabajo.')}}</div>
              <br>
              <div>{{$t('2- Planificación, actividades y contenidos: vinculación de actividades, propuestas, estrategias y contenidos con las demandas y necesidades educativas.')}} </div>
              <br>
              <div>{{$t('3- Organización del entorno educativo: aspectos sobre la organización del entorno en el que se desarrolla la práctica educativa a partir de la administración de variables tiempo y contexto.')}}</div>
              <br>
              <div>{{$t('4- Gestión escolar: determinaciones sobre la disposición de los recursos humanos, implementación de estrategias y trabajo en equipo para el desarrollo de los programas educativos de los estudiantes.')}} </div>
              <br>
              <div>{{$t('5- Sobre los profesionales: indicadores sobre el conocimiento específico de los profesionales para dar respuesta a las necesidades de los estudiantes, las dinámicas de trabajo colaborativo implementadas y la participación de la familia.')}} </div>
              <br>
              <div>{{$t('6- Sobre la participación de los estudiantes: comportamientos, manifestaciones e interacciones comunicativas observados en los estudiantes que son producto de las estrategias, decisiones y planes implementados en la escuela.')}} </div>
              <br>
              <div>{{$t('7- Sobre la familia: participación de la familia en los diferentes niveles y como miembros activos del equipo escolar.')}}</div>
            </v-expansion-panel-content>
          </v-expansion-panel>

          <v-expansion-panel>
            <v-expansion-panel-header>
              {{$t('Niveles de logro definidos por el resultado alcanzado')}}
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <v-divider class="mb-5"></v-divider>
              <div>{{$t('Para cumplimentar con los objetivos se definen los siguientes niveles de impacto')}}:</div>
              <br>
              <div><strong>{{$t('Focal')}}:</strong> {{$t('se identifican prácticas, acciones, decisiones o materiales que han sido implementados adecuadamente sólo en algún aspecto o por alguna persona de la comunidad educativa. Se desarrollan en un nivel específico y en tiempos discontinuos.')}}</div>
              <br>
              <div><strong>{{$t('En expansión')}}:</strong> {{('se identifican prácticas, acciones, decisiones o materiales implementados adecuadamente, en forma discontinua pero recurrente, realizadas por varias personas y en diversas áreas o escenarios.')}}</div>
              <br>
              <div><strong>{{$t('Transversal')}}:</strong> {{$t('se identifican prácticas, acciones, decisiones o materiales implementados adecuadamente y en forma más sistemática y periódica. Se apoya en diferentes niveles de gestión e involucra a varios actores, por lo que alcanza a la mayoría de las áreas o escenarios/espacios.')}}</div>
              <br>
              <div><strong>{{$t('Política institucional')}}:</strong>  {{$t('se identifican decisiones, acciones, proyectos de intención y prácticas institucionales innovadoras documentadas que involucran a todas las áreas y espacios de la escuela y a todos los miembros de la comunidad educativa.')}}</div>
              <br>
              <div><strong>{{$t('Cultura innovadora')}}:</strong> {{$t('existe evidencia de decisiones, acciones y proyectos orientados a la creación y desarrollo de estrategias innovadoras en forma habitual. Esto define un rasgo identitario particular de liderazgo en relación a otras prácticas, instituciones y al impacto en la comunidad.')}}</div>
              <br>
              <div>{{$t('NOTA: LA VALORACIÓN DE UN INDICADOR EN UN NIVEL SUPONE QUE TODOS LOS NIVELES ANTERIORES HAN SIDO SUPERADOS.')}}</div>
            </v-expansion-panel-content>
          </v-expansion-panel>

          <v-expansion-panel>
            <v-expansion-panel-header>
              {{$t('Implementación')}}
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <v-divider class="mb-5"></v-divider>
              <div>{{$t('La aplicación puede seguir dos estrategias: la observación directa Y/o la entrevista. Cada estrategia se complementa entre sí para poder recoger la información necesaria.')}}<br>
                {{$t('La información obtenida de la observación y/o de la entrevista se introduce en una hoja de cálculo en un proceso similar al seguido con la versión anterior de los PQI (2018). Cada criterio de evaluación se valora por separado según los diferentes niveles de logro. La herramienta calcula una puntuación para cada criterio, indicador y categoría, para posteriormente estimar el progreso general de un programa.')}}</div>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </div>
    </v-card>
  </v-col>
  </div>
</template>

<script>
export default {
  data: () => {
    return {

    }
  }
}
</script>
